<template>
  <div>
    <div class="more--actions">
      <div>
        <v-menu offset-y offset-x content-class="more--actions-container">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </div>
          </template>
          <div
            class="single--button"
            @click="activateHandler"
            v-if="category.id === 1 || category.id === 0"
          >
            {{ activated ? $t('general.btn.deactivate') : $t('general.btn.activate') }}
          </div>
          <div
            class="single--button"
            @click="repostHandler"
            v-if="category.id === 0 || category.id === 2"
          >
            {{ $t('general.btn.repost') }}
          </div>
          <!-- TODO Untuk enable schedule repost feature -->
          <!--          <div class="single&#45;&#45;button" @click="scheduleRepostHandler" v-if="category.id === 0">-->
          <!--            {{ $t('general.btn.scheduleRepost') }}-->
          <!--          </div>-->
          <div class="single--button">
            <router-link :to="editHref">
              {{ $t('general.btn.edit') }}
            </router-link>
          </div>
          <div v-if="category.id !== 0" class="single--button" @click="deleteHandler">
            {{ $t('general.btn.delete') }}
          </div>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'more-actions',
  props: ['listing'],
  components: {},
  computed: {
    ...mapState({
      category: (state) => state.listing.category,
    }),
    activated() {
      return this.category.id === 0;
    },
    editHref() {
      return `/listing/edit/${this.listing.uuid}`;
    },
  },
  methods: {
    activateHandler() {
      if (this.activated) {
        console.log('DEACTIVATE!');
        this.deactivate();
      } else {
        console.log('ACTIVATE!');
        this.$modal.show('activate-listing-modal', {
          submitAction: this.activate,
        });
      }
    },
    async activate() {
      try {
        const success = await this.$store.dispatch('listing/activate', this.listing.uuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.activate.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR ACTIVATE LISTING: ', e);
        let message = this.$i18n.t('listing.modal.activate.failed');
        if (e && e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        this.$swal({
          title: this.$i18n.t('general.failed'),
          text: message,
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$i18n.t('general.ok'),
          allowOutsideClick: false,
        });
      }
    },
    repostHandler() {
      this.$modal.show('repost-listing-modal', {
        submitAction: this.repost,
      });
    },
    async repost() {
      try {
        const success = await this.$store.dispatch('listing/repost', this.listing.uuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.repost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR ACTIVATE LISTING: ', e);
        // this.$swal({
        //   title: this.$i18n.t('listing.modal.repost.failedT'),
        //   text: this.$i18n.t('listing.modal.repost.failedM'),
        //   type: 'error',
        //   confirmButtonColor: '#3085d6',
        //   confirmButtonText: this.$i18n.t('general.ok'),
        //   allowOutsideClick: false,
        // });
      }
    },
    scheduleRepostHandler() {
      this.$modal.show('schedule-repost-listing-modal', {
        submitAction: this.scheduleRepost,
        listingUuid: this.listing ? this.listing.uuid : null,
      });
    },
    async scheduleRepost() {
      console.log('AYO SCHEDULE REPOST!');
      try {
        const success = await this.$store.dispatch(
          'listing/scheduleRepost',
          this.listing ? this.listing.uuid : null,
        );
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.scheduleRepost.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        console.log('ERROR SCHEDULE REPOST: ', e);
      }
    },
    async deactivate() {
      try {
        const result = await this.$swal({
          title: this.$i18n.t('listing.modal.deactivate.title'),
          text: this.$i18n.t('listing.modal.deactivate.message'),
          type: 'error',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('general.yes'),
          cancelButtonText: this.$i18n.t('general.cancel'),
          allowOutsideClick: false,
        });
        if (result.value) {
          const success = await this.$store.dispatch('listing/deactivate', this.listing.uuid);
          if (success) {
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t('listing.modal.deactivate.success'),
              'success',
            );
            this.$emit('actionDone');
          }
        }
      } catch (e) {
        console.log('ERROR DEACTIVATE LISTING: ', e);
        let message = this.$i18n.t('listing.modal.deactivate.failed');
        if (e && e.response && e.response.data && e.response.data.message) {
          message = e.response.data.message;
        }
        this.$swal({
          title: this.$i18n.t('general.failed'),
          text: message,
          type: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$i18n.t('general.ok'),
          allowOutsideClick: false,
        });
      }
    },
    deleteHandler() {
      this.$swal({
        title: this.$i18n.t('listing.modal.delete.title'),
        text: this.$i18n.t('listing.modal.delete.message'),
        type: 'error',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('general.yes'),
        cancelButtonText: this.$i18n.t('general.cancel'),
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    async delete() {
      try {
        const success = await this.$store.dispatch('listing/deleteListing', this.listing.uuid);
        if (success) {
          await this.$swal(
            this.$i18n.t('general.success'),
            this.$i18n.t('listing.modal.delete.success'),
            'success',
          );
          this.$emit('actionDone');
        }
      } catch (e) {
        // this.$swal({
        //   title: this.$i18n.t('listing.modal.delete.failedT'),
        //   text: this.$i18n.t('listing.modal.delete.failedM'),
        //   type: 'error',
        //   // showCancelButton: true,
        //   confirmButtonColor: '#3085d6',
        //   // cancelButtonColor: '#d33',
        //   confirmButtonText: this.$i18n.t('general.ok'),
        //   allowOutsideClick: false,
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
.more--actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  margin-right: -8px;

  .v-icon {
    font-size: $font-dl;
    @media #{$phones} {
      font-size: $font-xxl;
    }
  }
}
</style>
