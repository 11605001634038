<template>
  <VueSlickCarousel
    class="carousel--wrapper"
    v-bind="settings"
    @beforeChange="beforeChange"
    @afterChange="afterChange"
  >
    <div v-for="banner in banners" :key="banner.uuid" class="carousel--promo-slide">
      <router-link
        :to="`/banner/detail/${banner.uuid}`"
        class="carousel--promo-link"
        @click="clickHandler"
      >
        <!--      <div class="carousel&#45;&#45;promo-link mx-auto">-->
        <div class="carousel--promo-image-wrapper">
          <picture>
            <source :srcset="banner.image" type="image/webp" />
            <source :srcset="getFallbackImage(banner.image)" type="image/jpeg" />
            <img
              :src="getFallbackImage(banner.image)"
              class="carousel--promo-image img-responsive"
              :alt="`${banner.title} - BeliRumah`"
            />
          </picture>
          <router-link to="/promo" class="see--all" v-show="banner.is_promo">
            <div class="btn btn-primary">Lihat Semua Promo</div>
          </router-link>
        </div>
        <!--      </div>-->
      </router-link>
    </div>
    <template #prevArrow>
      <div class="custom-prev--arrow"></div>
    </template>
    <template #nextArrow>
      <div class="custom-next--arrow"></div>
    </template>
  </VueSlickCarousel>
</template>

<script>
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  props: {
    banners: {
      default: [],
      require: true,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      centerMode: true,
      dots: true,
      edgeFriction: 0.35,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesPerRow: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      // lazyLoad: 'progressive', // Ini kalo barengan multiple rows jadi gak bisa slide
      draggable: false,
    },
  }),
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
